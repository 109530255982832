<template>
<div>
    <loading v-if="loading" class="mt-12"/>
    <div v-if="!loading">
        
        <main class="white">

            <Slider class="sliderx" :currentItems="covers" :key="update" />

            <v-row style="margin-top: 8rem">
                <v-col cols="12" md="6" class="text-center" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
                    <div class="mx-4">
                        <h1 style="font-size: 4rem" class="primary--text">
                            دار لصحة
                            <br />
                            الـــــــــمـــــــــراة
                        </h1>
                        <h3 class="mt-12">
                            تلتزم مستشفى السيدة خديجة الكبرى للمرأة في تقديم
                            <br />
                            خدمات طبية الصحة علاجية ووقائية امنة وبتكلفة مقبولة
                        </h3>
                        <v-btn @click.prevent="scrollToSection('#about')" color="primary" class="btn my-12" x-large style="border-radius: 50px">
                            <h3 class="mx-2">
                                من نحن ؟
                            </h3>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="text-left">
                    <img src="../assets/images/about.png" style="width: 90%; height: auto;" />
                </v-col>
            </v-row>

            <br />

            <div class="primary pr-12 py-12" id="departments" style="margin-top: -50px">
                <v-row class="mx-12" justify="center" align="center">
                    <v-col  cols="12" xl="6" lg="6" md="6" class="white--text">
                        <div style="font-size: 30px; font-weight: bold">
                            اقسامنا
                        </div>
                        <div style="font-size: 20px; font-weight: 600">
                            اكتشفي الاقسام الموجودة في مستشفى خديجة الكبرى (ع)
                        </div>
                    </v-col>
                    <v-col class="text-end" cols="12" xl="6" lg="6" sm="6">
                        <a href="/departments">
                            <h3 link class="white--text">عرض جميع الاقسام
                                <v-icon color="white">fi fi-rr-angle-double-small-left</v-icon>
                            </h3>
                        </a>
                    </v-col>
                </v-row>
            </div>

            <div ref="slider" v-if="!loading" class="keen-slider primary ">
                <div class="keen-slider__slide text-center" v-for="(department,index) in departments" :key="index">
                    <v-container>
                        <v-card style="border-radius: 40px;" min-height="500px" outlined>
                            <v-container fluid>
                                <!-- <v-card-text> -->
                                <v-img style="border-radius: 30px" :src="$url + department.imageUrl" class="ma-4" height="280" />
                                <v-card :height="isMobile == true ? 200 : 200" flat>

                                    <div class="text-center mt-2" style="font-size: 20px; font-weight: bold; color: #27354A">
                                        {{ department.name }}
                                    </div>
                                    <div class="text-start mt-2 mx-3 ellipsis-container-rtl">
                                        {{ department.shortDescription }}
                                    </div>
                                </v-card>
                                <v-btn block :to="'/department/'+department.id" color="primary" class="btn" x-large style="font-size: 16px; font-weight: normal; border-radius: 50px">
                                    <h3>
                                        عرض التفاصيل
                                    </h3>
                                </v-btn>
                            </v-container>
                        </v-card>
                    </v-container>
                </div>
            </div>

            <div style="background-color: #FAFAFA" class="py-12" id="devices">
                <!-- <v-row class="mx-12" justify="center" align="center">
                    <v-col  cols="12" xl="6" lg="6" md="6">
                        <div style="font-size: 40px; font-weight: bold">
                            اجهزتنا
                        </div>
                        <div style="font-size: 14px; font-weight: 600">
                            اكتشفي الاجهزة الموجودة في مستشفى خديجة الكبرى (ع)
                        </div>
                    </v-col>
                    <v-col class="text-end" cols="12" xl="6" lg="6" sm="6">
                        <a href="/devices">
                            <h3 link class="primary--text">عرض جميع الاجهزة
                                <v-icon color="primary">fi fi-rr-angle-double-small-left</v-icon>
                            </h3>
                        </a>
                    </v-col>
                </v-row> -->

                <div class=" pr-12 py-12" id="departments" style="margin-top: -50px">
                    <v-row class="mx-12" justify="center" align="center">
                        <v-col  cols="12" xl="6" lg="6" md="6" >
                            <div style="font-size: 30px; font-weight: bold">
                                اجهزتنا
                            </div>
                            <div style="font-size: 20px; font-weight: 600">
                                اكتشفي الاجهزة الموجودة في مستشفى خديجة الكبرى (ع)
                            </div>
                        </v-col>
                        <v-col class="text-end" cols="12" xl="6" lg="6" sm="6">
                            <a href="/devices">
                                <h3 link >عرض جميع الاجهزة
                                    <v-icon color="primary">fi fi-rr-angle-double-small-left</v-icon>
                                </h3>
                            </a>
                        </v-col>
                    </v-row>
                </div>

                <div ref="slider3" v-if="!loading" class="keen-slider  ">
                    <div class="keen-slider__slide " v-for="(device,index) in devices" :key="index">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="4" data-aos="fade-left">
                                    <v-card @click="()=>1" link :to="'/device/' + device.id" style="border-radius: 40px;" outlined height="270">
                                        <v-img :src="$url+ device.imageUrl" height="100%" />
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="8" data-aos="zoom-in-down">
                                    <v-card @click="()=>1" link :to="'/device/' + device.id" style="border-radius: 40px;" outlined height="270">
                                        <v-container class="ma-4">
                                            <h3 class="primary--text">{{ device.name }}</h3>
                                            <div class="truncate" v-html="truncateText(device.content, 450)"></div>
                                        </v-container>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>

            </div>

            <div class="py-12" v-if="markedDoctor">
                <div class="primary--text pr-12 mr-12">
                    <div style="font-size: 30px; font-weight: bold">
                        طبيبنا لهذا الاسبوع
                    </div>
                </div>
                <v-container fluid>
                    <v-row justify="center" align="center">
                        <v-col cols="12" md="3">
                            <v-card style="border-radius: 30px;" height="full" flat>
                                <v-avatar style="border-radius: 30px;" height="100%" width="100%" v-if="markedDoctor.imageUrl">
                                    <v-img :src="$url+'/'+markedDoctor.imageUrl" />
                                </v-avatar>
                                <v-avatar style="border-radius: 30px" height="100%" width="100%" v-else>
                                    <img contain src="@/assets/images/photo.svg">
                                </v-avatar>
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="9">
                            <v-card data-aos="fade-left" style="border-radius: 30px;" class="mx-auto" outlined>
                                <v-container>
                                    <div class="text-start">
                                        <h1 class="mt-6 primary--text">
                                            {{ markedDoctor.fullName }}
                                        </h1>
                                        <h3 class="secondary--text">
                                            {{ markedDoctor.description }}
                                        </h3>
                                    </div>
                                    <v-divider class="my-4" />
                                    <div style="font-size: 14px; " v-html="markedDoctor.content"></div>
                                </v-container>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-container>
            </div>

            <div class="primary pr-12 py-12" id="departments" style="margin-top: -50px">
                <div class="white--text">
                    <div style="font-size: 50px; font-weight: bold ">
                        اخر الاخبار
                    </div>
                </div>
            </div>

            <div ref="slider2" v-if="!loading" class="keen-slider primary ">
                <div class="keen-slider__slide text-center" v-for="(dr,index) in news" :key="index">
                    <v-container>
                        <v-card style="border-radius: 40px;" min-height="500px" outlined>
                            <v-container fluid>
                                <!-- <v-card-text> -->
                                <v-img height="280" style="border-radius: 30px" :src="$url + dr.imageUrl" class="ma-4" />
                                <v-card :height="isMobile == true ? 120 : 220" flat>

                                    <div class="text-center mt-2" style="font-size: 20px; font-weight: bold; color: #27354A">
                                        {{ dr.name }}
                                    </div>
                                    <div class="text-start mt-2 mx-3" style="font-size: 18px; font-weight: normal; color: #27354A">
                                        {{ dr.shortDescription }}
                                    </div>
                                </v-card>

                            </v-container>
                            <v-card-actions>
                                <v-btn block :to="'/news/'+dr.id" color="primary" class="btn" x-large style="font-size: 16px; font-weight: normal; border-radius: 50px;">
                                    <h3>
                                        عرض التفاصيل
                                    </h3>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-container>
                </div>
            </div>
            <v-container fluid class="mt-12">
                <v-row justify="center" align="center" class="text-center">
                    <v-col cols="12" md="3" v-for="(item,index) in counters" :key="index">
                        <!-- <h1 class="secondary--text">{{item.subtitle}}</h1> -->
                        <v-progress-circular :rotate="90*index" :size="150" :width="20" :value="value" :color="item.color">
                            <h2 :class="item.color+'--text'" style="font-size: 24px; font-weight: 600">{{item.subtitle}}</h2>
                        </v-progress-circular>
                        <h2 class="black--text my-6" style="font-size: 24px; font-weight: 600">{{ item.title }}</h2>
                    </v-col>
                </v-row>
            </v-container>

            <!-- contact us start -->
            <v-row justify="center" align="center" class="mx-auto">
                <v-col cols="12" md="6">
                    <div class="white py-12">
                        <v-container>
                            <v-card color="primary" style="border-radius: 30px" dark outlined>
                                <v-form ref="form" v-model="valid">
                                    <v-container>
                                        <v-row class="text-center mx-4" justify="center" align="center">
                                            <v-col cols="12">
                                                <h1>تواصلي معنا</h1>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field :rules="[required()]" v-model="message.firstName" label="الاسم الاول" filled outlined rounded hide-details="auto" />
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field :rules="[required()]" v-model="message.lastName" label="الاسم الثاني" filled outlined rounded hide-details="auto" />
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field :rules="[required()]" dir="ltr" v-model="message.phoneNumber" label="رقم الهاتف" filled outlined rounded hide-details="auto" />
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field v-model="message.email" label="البريدالالكتروني" filled outlined rounded hide-details="auto" />
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <v-textarea :rules="[required()]" v-model="message.details" label="التفاصيل" filled outlined rounded hide-details="auto" />
                                            </v-col>
                                            <v-col cols="12">
                                                <v-btn x-large rounded class="btn" @click="sendMessage()" :loading="$global.state.loading" :disabled="!valid">
                                                    <h3 class="mx-8">ارسال</h3>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card>
                        </v-container>
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9198.223534211425!2d44.038545152873915!3d32.59820840370001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15596b005a4a8817%3A0xa37aedd0bd1c589a!2z2YXYs9iq2LTZgdmJINin2YTYs9mK2K_YqSDYrtiv2YrYrNipINin2YTZg9io2LHZiQ!5e0!3m2!1sar!2siq!4v1717539179493!5m2!1sar!2siq" width="100%" height="450" style="border:0; border-radius: 30px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6753.431736140664!2d44.689305206233186!3d32.29724113342761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1558ebdaca058c79%3A0x841f16eb430f0a36!2z2YXYs9iq2LTZgdmJINiu2K_Zitis2Ycg2KfZhNmD2KjYsdmJ!5e0!3m2!1sar!2siq!4v1703036130717!5m2!1sar!2siq" width="100%" height="450" style="border:0; border-radius: 30px" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
                </v-col>
            </v-row>
            <!-- contact us end -->

            <AFooter />
        </main>
    </div>

</div>
</template>

<script>
import "keen-slider/keen-slider.min.css";
import KeenSlider from "keen-slider";
import Slider from "../components/Slider.vue"
import validations from '@/utils/validations'
import AOS from 'aos';
import 'aos/dist/aos.css';
import AFooter from '../components/Footer.vue'
export default {

    components: {
        Slider,
        AFooter,
    },

    mounted() {
        // this.slider = new KeenSlider(this.$refs.slider, {
        //     loop: true,
        //     rtl: true,
        //     slides: {
        //         perView: this.isMobile == true ? 1 : 5,
        //         spacing: 10,
        //     },
        // });
        AOS.init();

        // Refresh AOS whenever the component is updated
        this.$nextTick(() => {
            AOS.refresh();
        });
        this.interval = setInterval(() => {
            if (this.value === 100) {
                return (this.value = 0)
            }
            this.value += 10
        }, 1000)

    },

    updated() {
        AOS.refresh();
    },

    beforeDestroy() {
        if (this.slider) this.slider.destroy();
        if (this.slider2) this.slider2.destroy();
        clearInterval(this.interval)
    },

    created() {
        // Call all asynchronous functions and store their promises
        const promises = [
            this.getDepartment(),
            this.getSliderClinics(),
            this.getDevices(),
            // this.getDoctors(),
            this.getNews(),
            this.getMarkedDoctor(),
            this.getCovers(),
            this.getCounters()
        ];

        Promise.all(promises)
            .then(() => {
                setTimeout(() => {
                    this.update++
                }, 3000);
                this.onResize();
                window.addEventListener("resize", this.onResize, {
                    passive: true
                });

                if (this.isMobile) {
                    this.drawer = false;
                }
                localStorage.setItem('tabs', JSON.stringify(this.tabs))
                this.$store.dispatch("setTabs");
            })
            .catch(error => {
                console.error("An error occurred:", error);
            });
    },

    watch: {
        beforeDestroy() {
            if (typeof window !== "undefined") {
                window.removeEventListener("resize", this.onResize, {
                    passive: true
                });
            }
        }
    },

    methods: {

        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        },

        async getCounters() {
            this.loading = true
            try {
                let res = await this.$http.get(`counters`)
                this.counters = res.data
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getDevices() {
            this.loading = true
            try {
                let res = await this.$http.get(`Device?PageIndex=1&PageSize=10`)
                this.devices = res.data.result
                for (let i = 0; i < res.data.result.length; i++) {
                    this.tabs[2].children.push({
                        "title": res.data.result[i].name,
                        "link": "/device/" + res.data.result[i].id
                    })
                }
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }

            setTimeout(() => {
                this.slider3 = new KeenSlider(this.$refs.slider3, {
                    loop: true,
                    rtl: true,
                    slides: {
                        perView: this.isMobile == true ? 1 : 5,
                        spacing: 10,
                    },
                });
                this.update++
            }, 100);
        },

        async getNews() {
            this.loading = true
            try {
                let res = await this.$http.get(`Post?PageIndex=1&PageSize=10`)
                this.news = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }

            setTimeout(() => {
                this.slider2 = new KeenSlider(this.$refs.slider2, {
                    loop: true,
                    rtl: true,
                    slides: {
                        perView: this.isMobile == true ? 1 : 5,
                        spacing: 10,
                    },
                });
                this.update++
            }, 100);
        },

        async getCovers() {
            this.loading = true
            try {
                let res = await this.$http.get(`HomeCoverImages`)
                this.covers = res.data
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getDepartment() {
            this.loading = true
            try {
                let res = await this.$http.get(`Department?PageIndex=1&PageSize=10`)
                this.departments = res.data.result
                for (let i = 0; i < res.data.result.length; i++) {
                    this.tabs[1].children.push({
                        "title": res.data.result[i].name,
                        "link": "/department/" + res.data.result[i].id
                    })
                }

                setTimeout(() => {
                    this.slider = new KeenSlider(this.$refs.slider, {
                        loop: true,
                        rtl: true,
                        slides: {
                            perView: this.isMobile == true ? 1 : 5,
                            spacing: 10,
                        },
                    });
                    this.update++
                }, 100);

            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getSliderClinics() {
            this.loading = true
            try {
                let res = await this.$http.get(`SliderClinic`)
                this.sliderClinics = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getDoctors() {
            this.loading = true
            try {
                let res = await this.$http.get(`Doctor`)
                this.doctors = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async getMarkedDoctor() {
            this.loading = true
            try {
                let res = await this.$http.get(`Doctor/MarkedDoctor`)
                this.markedDoctor = res.data.result
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async sendMessage() {
            this.$global.state.loading = true
            this.message.fullName = this.message.firstName + this.message.lastName
            this.$http.post(`inbox`, this.message).then(res => {
                this.$service.success('تم الارسال بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.message = {
                    "firstName": "",
                    "lastName": "",
                    "phoneNumber": "",
                    "email": "",
                    "details": ""
                }
                this.$refs.form.resetValidation()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.stringify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },
        onResize() {
            this.isMobile = window.innerWidth < 1000;
            this.slider = new KeenSlider(this.$refs.slider, {
                loop: true,
                rtl: true,
                slides: {
                    perView: this.isMobile == true ? 1 : 5,
                    spacing: 10,
                },
            });
            this.slider2 = new KeenSlider(this.$refs.slider2, {
                loop: true,
                rtl: true,
                slides: {
                    perView: this.isMobile == true ? 1 : 5,
                    spacing: 10,
                },
            });

            setTimeout(() => {
                this.slider3 = new KeenSlider(this.$refs.slider3, {
                    loop: true,
                    rtl: true,
                    slides: {
                        perView: this.isMobile == true ? 1 : 2,
                        spacing: 10,
                    },
                });
                this.update++
            }, 1000);

            this.update++
        },

        scrollToSection(link) {
            const section = document.querySelector(link);
            if (section) {
                section.scrollIntoView({
                    behavior: 'smooth'
                });
            }
        },

        selectTab(index, link) {
            this.selectedTab = link;
            this.scrollToSection(link);
        }
    },

    data() {
        return {
            interval: {},
            value: 0,
            model: 0,
            loading: false,
            valid: false,
            ...validations,
            update: 0,
            markedDoctor: {},
            message: {
                "firstName": "",
                "lastName": "",
                "phoneNumber": "",
                "email": "",
                "details": ""
            },
            isMobile: false,
            drawer: true,
            isIntersecting: false,
            selectedTab: null,
            doctors: [],
            covers: [],
            counters: [],
            tabs: [{
                    "title": "الصفحة الرئيسية",
                    "link": "/home",
                    "children": []
                },
                {
                    "title": "الاقسام",
                    "link": "/departments",
                    "children": []
                },
                {
                    "title": "الاجهزة",
                    "link": "/devices",
                    "children": []
                },
                {
                    "title": "الاخبار",
                    "link": "/news",
                    "children": []
                },
                {
                    "title": "جدول العيادات",
                    "link": "/shedual",
                    "children": []
                },
                {
                    "title": "كادرنا الطبي",
                    "link": "/ourDoctors",
                    "children": []
                },
                {
                    "title": "حول المستشفى",
                    "link": "/about",
                    "children": []
                },
            ],

            sliderClinics: [{}],
            devices: [],
            news: [],
            posts: [],
            departments: []
        }
    },
};
</script>

<style>
.custom-app-bar {
    position: fixed !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(8px);
}

main {
    padding: 0 !important;
}

.selected-item {
    background-color: #F494BE;
    color: #ffffff;
    transition: background-color 0.3s ease;
    padding-right: 30px;
    transition: padding-right 0.3s ease;

}

.svg-container {
    width: 300px;
    height: 150px;
    resize: both;
    overflow: auto;
    border: 1px dashed #aaa;
}

svg {
    width: 95%;
    height: 95%;
}

.head {
    width: 100%;
    display: block;
}

.text-overlay {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 6vw;
    font-weight: bold;
    text-align: center;
    max-width: 80%;
}

.text-overlay2 {
    position: absolute;
    top: 40%;
    left: 75%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 4vw;
    /* Responsive font size */
    font-weight: bold;
    text-align: center;
    /* Center the text */
    max-width: 80%;
    /* Maximum width of the text */
    overflow: hidden;
    /* Ensures text stays within bounds */
}

.bubbly-button {
    font-size: 1.2em;
    padding: 1em 1.3em;
    margin-top: 100px;
    margin-bottom: 40px;
    background-color: #F495BD;
    font-weight: bold;
    color: #fff;
    border-radius: 60px;
    border: none;
    cursor: pointer;
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
}

.bubbly-button:active {
    transform: scale(0.9);
    background-color: darken(#ff0081, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.bubbly-button:active:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255);
    animation: bubble 0.5s ease-out forwards;
}

@keyframes bubble {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}
</style>
