<template>
    <div dir="ltr">
      <div class="navigation-wrapper">
        <v-card class="slider-container">
            <div ref="slider" class="keen-slider">
                <div v-for="(item, index) in currentItems" :key="index" class="keen-slider__slide">
                     <div class="image-container" id="home">
                         <v-img cover eager lazy-src="https://picsum.photos/id/11/10/6" :src="$url+'/'+ (isMobile ? (item.mobileImageUrl == null ? item.imageUrl : item.mobileImageUrl) : item.imageUrl)" class="head slider-image" alt="Head Image">
                             <div class="text-overlay">
                                 {{ item.description }}
                             </div>
                             <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                         </v-img>
                     </div>
                </div>
             </div>
        </v-card>
        <svg 
          @click="slider.prev()"
          :class="{
            arrow: true,
            'arrow--left': true,
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          ></path>
        </svg>
        <svg
          v-if="slider"
          @click="slider.next()"
          :class="{
            arrow: true,
            'arrow--right': true,
            'arrow--disabled': current === slider.track.details.slides.length - 1,
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"></path>
        </svg>
      </div>
      <div v-if="slider" class="dots">
        <button
          v-for="(_slide, idx) in dotHelper"
          @click="slider.moveToIdx(idx)"
          :class="{ dot: false, active: current === idx }"
          :key="idx"
        ></button>
      </div>
    </div>
  </template>
  
  <script>
  import "keen-slider/keen-slider.min.css"
  import KeenSlider from "keen-slider"
  export default {
    name: "Slider",
    props:['currentItems'],
    computed: {
      dotHelper() {
        return this.slider ? [...Array(this.slider.track.details.slides.length).keys()] : []
      },
    },
    data() {
      return {
        current: 1,
        slider: null,
        isMobile: false, 
      }
    },
    methods: {
      preloadImages() {
      this.currentItems.forEach(item => {
        const img = new Image();
        img.src = this.$url + '/' + item.imageUrl;
      });
    },
    onResize() {
            this.isMobile = window.innerWidth < 1000;
        },
    },
    mounted() {
    this.preloadImages();
    this.slider = new KeenSlider(
      this.$refs.slider,
      {
        loop: true,
      },
      [
        (slider) => {
          let timeout
          let mouseOver = false
          function clearNextTimeout() {
            clearTimeout(timeout)
          }
          function nextTimeout() {
            clearTimeout(timeout)
            if (mouseOver) return
            timeout = setTimeout(() => {
              slider.next()
            }, 2000)
          }
          slider.on("created", () => {
            slider.container.addEventListener("mouseover", () => {
              mouseOver = true
              clearNextTimeout()
            })
            slider.container.addEventListener("mouseout", () => {
              mouseOver = false
              nextTimeout()
            })
            nextTimeout()
          })
          slider.on("dragStarted", clearNextTimeout)
          slider.on("animationEnded", nextTimeout)
          slider.on("updated", nextTimeout)
        },
      ]
    )

    this.onResize();
        window.addEventListener("resize", this.onResize, {
            passive: true
        });
  },
    beforeDestroy() {
      if (this.slider) this.slider.destroy()
      if (typeof window !== "undefined") {
                window.removeEventListener("resize", this.onResize, {
                    passive: true
                });
            }
    },
  }
  </script>
  
  <style>
  .head {
    display: block;
    transition: transform 0.3s ease-in-out; 
  }


  [class^="number-slide"],
  [class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 350px;
  }
  .navigation-wrapper {
    position: relative;
    height: calc(100vh - 64px) !important;
    width: 100%;
  }
  .dots {
    display: flex;
    padding: 10px 0;
    justify-content: center;
  }
  .dot {
    border: none;
    width: 10px;
    height: 10px;
    background: #c5c5c5;
    border-radius: 50%;
    margin: 0 5px;
    padding: 5px;
    cursor: pointer;
  }
  .dot:focus {
    outline: none;
  }
  .dot.active {
    background: #000;
  }
  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }
  .arrow--left {
    left: 5px;
  }
  .arrow--right {
    left: auto;
    right: 5px;
  }
  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
  </style>