var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"dir":"ltr"}},[_c('div',{staticClass:"navigation-wrapper"},[_c('v-card',{staticClass:"slider-container"},[_c('div',{ref:"slider",staticClass:"keen-slider"},_vm._l((_vm.currentItems),function(item,index){return _c('div',{key:index,staticClass:"keen-slider__slide"},[_c('div',{staticClass:"image-container",attrs:{"id":"home"}},[_c('v-img',{staticClass:"head slider-image",attrs:{"cover":"","eager":"","lazy-src":"https://picsum.photos/id/11/10/6","src":_vm.$url+'/'+ (_vm.isMobile ? (item.mobileImageUrl == null ? item.imageUrl : item.mobileImageUrl) : item.imageUrl),"alt":"Head Image"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[_c('div',{staticClass:"text-overlay"},[_vm._v(" "+_vm._s(item.description)+" ")])])],1)])}),0)]),_c('svg',{class:{
        arrow: true,
        'arrow--left': true,
      },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.slider.prev()}}},[_c('path',{attrs:{"d":"M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"}})]),(_vm.slider)?_c('svg',{class:{
        arrow: true,
        'arrow--right': true,
        'arrow--disabled': _vm.current === _vm.slider.track.details.slides.length - 1,
      },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"},on:{"click":function($event){return _vm.slider.next()}}},[_c('path',{attrs:{"d":"M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"}})]):_vm._e()],1),(_vm.slider)?_c('div',{staticClass:"dots"},_vm._l((_vm.dotHelper),function(_slide,idx){return _c('button',{key:idx,class:{ dot: false, active: _vm.current === idx },on:{"click":function($event){return _vm.slider.moveToIdx(idx)}}})}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }